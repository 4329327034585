<template>
  <div>
    <NavBar :list="navList" v-if="navshow" name="商户账单"></NavBar>
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  data () {
    return {
      navList: [
        {
          name: '基本账单',
          path: '/charge/merchantbill/basebill'
        },
        // {
        //   name: '日常费用账单',
        //   path: '/charge/merchantbill/daybill'
        // }
      ],
      navshow: true

    };
  },
  watch: {
    '$route': {
      handler (e) {
        if (e.fullPath == '/charge/merchantbill/basebill' || e.fullPath == '/charge/merchantbill/daybill') {
          this.navshow = true;
        } else {
          this.navshow = false
        }
      },
      immediate: true
    }
  },
  mounted () { }
}
</script>
<style lang='scss'></style>
